.green-letters-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 25px;
}

.green-letter-wrapper {
}

.positioned-yellow-letters-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 25px;
  margin-top: 0px;
  flex: 1;
  width: 100%;
}

.positioned-yellow-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  flex: 1;
}

.positioned-yellow-letter-field {
  margin: 2px;
  border: 2px solid rgba(128, 128, 128, 0.8);
  flex: 1;
  max-width: 40px;
  height: 40px;
  font-size: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  font-weight: bold;
  user-select: none;
  text-align: center;
}

.text-area-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 25px;
  margin-top: 0px;
}

textarea {
  letter-spacing: 0.5em;
}

.yellow-letter-textarea {
  flex: 1;
  border: 2px solid rgba(128, 128, 128, 0.8);
  font-size: 32px;
  text-transform: uppercase;
  font-weight: bold;
  text-align: center;
}

textarea {
}

.gray-letter-textarea {
  flex: 1;
  border: 2px solid rgba(128, 128, 128, 0.8);
  font-size: 28px;
  text-transform: uppercase;
  font-weight: bold;
  text-align: center;
}

.green-letter-field {
  margin: 2px;
  border: 2px solid rgba(128, 128, 128, 0.8);
  flex: 1;
  max-width: 40px;
  height: 40px;
  font-size: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  font-weight: bold;
  user-select: none;
  text-align: center;
}

.focused {
  border: 3px solid black;
}

.container {
  display: flex;
  align-items: flex-start;
  background: #121213;
  color: white;
}

textarea,
input {
  background: transparent;
  color: white;
  resize: none;
}

.word-list {
  list-style: none;
}

.word-list li {
  text-transform: uppercase;
  letter-spacing: 1em;
  font-size: 24px;
  font-family: monospace;
}

.word-list-container {
  height: 100vh;
  overflow-y: scroll;
  flex: 1;
}

.reset-button-container {
  display: flex;
  justify-content: center;
}

.reset-button-container button {
  font-size: 24px;
}

.controls {
  height: 100%;
  display: flex;
  flex-direction: column;
  height: 100vh;
}
